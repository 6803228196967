import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import { useAuth } from "./AuthProvider";//new code

import Topbar from "./pages/global/Topbar";
import Dashboard from "./pages/dashboard/dashboard";
import SignIn from "./pages/signin/signin";
import OrganisationForm from "./pages/organisationForm/organisationForm";
import Employee from "./pages/Employee/employee";
import EmployeeRegForm from "./pages/EmployeeRegistration/EmployeeRegistration";
import EmployeeAttendance from "./pages/Attedance/attendance";
import EmployeeLeave from "./pages/Leave/leave";
import EmployeePaySlip from "./pages/PaySlip/paySlips";
import EmployeeDetails from "./pages/EmployeeDetails/EmployeeDetails";
import EmployeeAttendanceDetails from "./pages/EmployeeAttendanceDetails/EmployeeAttendanceDetails";
import Profile from "./pages/Profile/Profile";
import PaySlipDoc from "./pages/PaySlipDocument/DocPaySlip";
import AbsentsList from "./pages/AbsentsList/absentsList";
import ChangePassword from "./pages/ChangePassword/changePassword";
import { CompanyProvider } from "./pages/CompanyContext/CompanyContext";
import HolidayCalendar from "./pages/HolidayCalendar/holidayCalendar";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";



const App = () => {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const { companyEmailId } = useAuth(); // Access authentication state// new code
  const [authenticated, setAuthenticated] = useState(false);

  
  //Redirect to login if not authenticated and show alert
  // const RequireAuth = ({ children }) => {
  //   if (!companyEmailId) {
  //     alert("You need to Log In to access this page.");
  //     return <Navigate to="/" replace />;
  //   }
  //   return children;
  // };

  const RequireAuth = ({ children }) => {
    // Check if the companyEmailId is available in localStorage
    const companyEmailId = localStorage.getItem("companyEmailId");
  
    // Debugging: log the value of companyEmailId
    console.log("companyEmailId:", companyEmailId);
  
    // If companyEmailId is missing, user is not authenticated, redirect to login page
    if (!companyEmailId) {
      // alert("You need to Log In to access this page.");
      return <Navigate to="/" replace />;
    }
  
    // If authenticated, render the children (protected routes)
    return children;
  };

 

  const checkAuthentication = () => {
    const publicRoutes = ["/", "/signup"];
    const isPublicRoute = publicRoutes.includes(location.pathname);
    setAuthenticated(!isPublicRoute);
  };

  useEffect(() => {
    checkAuthentication();
  }, [location.pathname]);



  // Check if the current route is valid
  const isNotFound = () => {
    // Define regex for dynamic routes
    const validRoutes = [
      /^\/dashboard$/,
      /^\/employee$/,
      /^\/employeeRegForm$/,
      /^\/employeeAttendance$/,
      /^\/leaves$/,
      /^\/paySlip$/,
      /^\/paySlipDocument$/,
      /^\/employeeDetails\/\d+$/, // Matches /employeeDetails/:empId
      /^\/attendance\/\d+$/, // Matches /attendance/:empId
      /^\/profile$/,
      /^\/absentsList$/,
      /^\/changePassword$/,
      /^\/HolidayCalendar$/,
    ];

    return !validRoutes.some((route) => route.test(location.pathname));
  };


  return (
    <CompanyProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* If the user is authenticated */}
          {authenticated && !isNotFound() ? (
            <MyProSidebarProvider>
              <div style={{ height: "100%", width: "100%" }}>
                <Topbar />
                <main>
                <Routes>
                  <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
                  <Route path="/employee" element={<RequireAuth><Employee /></RequireAuth>} />
                  <Route path="/employeeRegForm" element={<RequireAuth><EmployeeRegForm /></RequireAuth>} />
                  <Route path="/employeeAttendance" element={<RequireAuth><EmployeeAttendance /></RequireAuth>} />
                  <Route path="/leaves" element={<RequireAuth><EmployeeLeave /></RequireAuth>} />
                  <Route path="/paySlip" element={<RequireAuth><EmployeePaySlip /></RequireAuth>} />
                  <Route path="/paySlipDocument" element={<RequireAuth><PaySlipDoc /></RequireAuth>} />
                  <Route path="/employeeDetails/:empId" element={<RequireAuth><EmployeeDetails /></RequireAuth>} />
                  <Route path="/attendance/:empId" element={<RequireAuth><EmployeeAttendanceDetails /></RequireAuth>} />
                  <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
                  <Route path="/absentsList" element={<RequireAuth><AbsentsList /></RequireAuth>} />
                  <Route path="/changePassword" element={<RequireAuth><ChangePassword /></RequireAuth>} />
                  <Route path="/HolidayCalendar" element={<RequireAuth><HolidayCalendar /></RequireAuth>} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                </main>
              </div>
            </MyProSidebarProvider>
          ) : (
            <Routes>
              {/* Non-authenticated routes */}
              <Route path="/" element={<SignIn />} />
              <Route path="/signup" element={<OrganisationForm />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          )}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </CompanyProvider>
  );
};

export default App;
